<template>
  <v-app id="app">
    <main>
      <router-view />
    </main>
  </v-app>
</template>

<script>
export default {
  metaInfo: {
    meta: [{ name: "theme-color", content: "#1976D2" }]
  },
  name: "App",

  data: () => ({
    //
  })
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Cairo&display=swap");

#app {
  font-family: Cairo, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
